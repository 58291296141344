





















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      observer: undefined as IntersectionObserver | undefined,
    };
  },
  computed: {
    ...mapGetters({
      highlights: 'highlight/all',
    }),
  },
  async mounted() {
    await this.$store.dispatch('highlight/bind');
    this.setupHighlightsObserver();
  },
  methods: {
    setupHighlightsObserver() {
      const highlights = this.highlights;
      const analytics = this.$analytics;
      this.observer = new IntersectionObserver(
        function (entries) {
          const entry = entries[0];
          if (entry.isIntersecting === true) {
            const id = parseInt(entry.target.id);
            const name = highlights[id].name;
            analytics.logEvent('highlight.show', { name: name });
          }
        },
        { threshold: 0.5 }
      );
    },
    onLoad(event: Event) {
      this.observer?.observe(event.target as Element);
    },
    onClick(name: string) {
      this.$analytics.logEvent('highlight.tap', { name: name });
    },
  },
});
