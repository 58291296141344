































import Tour from '@/dtos/tour';
import Vue from 'vue';
export default Vue.extend({
  props: {
    tour: {
      type: Tour,
      required: true,
    },
  },
});
