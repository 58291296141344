

































import Vue from 'vue';
export default Vue.extend({
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
});
