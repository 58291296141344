

























































import Tour from '@/dtos/tour';
import { formatIntoComponents } from '@/utils/date_time';
import { formattedTourFacts } from '@/utils/tour.formatter';
import Vue from 'vue';
export default Vue.extend({
  props: {
    tour: {
      type: Tour,
      required: true,
    },
  },
  data() {
    return {
      relative: undefined as string | undefined,
      weekday: undefined as string | undefined,
      date: undefined as string | undefined,
      time: undefined as string | undefined,
    };
  },
  computed: {
    location(): string | null {
      //Handle legacy tours created before introducing location field
      const location = this.tour.location;
      if (!location) return null;
      return location.description;
    },
    tourFacts(): string {
      return formattedTourFacts(this.tour);
    },
  },
  mounted() {
    const c = formatIntoComponents(this.tour.startDateTime, true);
    this.relative = c.relative;
    this.weekday = c.weekday;
    this.date = c.date;
    this.time = c.time;
  },
});
