









































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import BottomMenu from '@/components/Mobile/BottomMenu.vue';
import RideeUser from '@/dtos/user';
import UserListHorizontal from '@/user/UserListHorizontal.vue';
import algoliasearch from 'algoliasearch/lite';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import TourArchiveWidget from '@/tour/TourArchiveWidget.vue';
import UpcomingWidget from '@/upcoming/UpcomingWidget.vue';
import HighlightsWidget from '@/highlights/HighlightsWidget.vue';
import { halfADayAgo } from '@/utils/date_time';

export default Vue.extend({
  components: {
    UserListHorizontal,
    BottomMenu,
    TourArchiveWidget,
    UpcomingWidget,
    HighlightsWidget,
  },
  data() {
    return {
      users: [] as RideeUser[],
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_API_KEY
      ),
      futureRefinement: {
        //referenceDateTime is introduced and exists
        //only on Algolia. It's derived as following:
        //for tour referenceDateTime = startDateTime
        //for event referenceDateTime = endDateTime or startDateTime if endDateTime undefined
        //so ongoing multiday events are listed untill they end
        referenceDateTime: {
          '>': [halfADayAgo / 1000],
        },
      },
      pastRefinement: {
        referenceDateTime: {
          '<': [halfADayAgo / 1000],
        },
      },
      routing: {
        router: history(),
        stateMapping: simple(),
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: 'isLoading',
      user: 'auth/getUser',
    }),
    version(): string {
      return process.env.PACKAGE_VERSION || '';
    },
    algoliaFutureToursIndexName() {
      return process.env.VUE_APP_ALGOLIA_TOUR_INDEX;
    },
    algoliaPastToursIndexName() {
      return process.env.VUE_APP_ALGOLIA_TOUR_INDEX_ARCHIVE;
    },
  },
  mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('user/fetchActive').then((fetched) => (this.users = fetched));
  },
  methods: {
    showIntercom() {
      window.Intercom('show');
    },
  },
});
