<template>
  <ol v-if="state" class="space-y-2">
    <li v-for="hit in state.hits" :key="hit.objectID">
      <upcoming-widget-event-cell
        v-if="hit.isEvent"
        :event="eventFromAlgolia(hit)"
      />
      <upcoming-widget-tour-cell
        v-else
        :tour="tourFromAlgolia(hit)"
      ></upcoming-widget-tour-cell>
    </li>
  </ol>
</template>
<script>
import Vue from 'vue';
import Tour from '@/dtos/tour';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectHits } from 'instantsearch.js/es/connectors';
import UpcomingWidgetTourCell from '@/upcoming/UpcomingWidgetTourCell.vue';
import UpcomingWidgetEventCell from './UpcomingWidgetEventCell.vue';
import RawRideeEvent from '@/event/event.raw';

export default Vue.extend({
  components: { UpcomingWidgetTourCell, UpcomingWidgetEventCell },
  mixins: [createWidgetMixin({ connector: connectHits })],
  methods: {
    tourFromAlgolia(hit) {
      return Tour.fromAlgoliaHit(hit);
    },
    eventFromAlgolia(hit) {
      return RawRideeEvent.fromAlgoliaHit(hit).toEvent();
    },
  },
});
</script>
