import Tour from '@/dtos/tour';
import { formatString } from './string-formatter';

export const formattedPace = (pace: number): string =>
  formatString('{0} km/h', pace);
export const formattedAltitude = (alt: number): string =>
  formatString('{0} m', alt);
export const formattedDistance = (distance: number): string =>
  formatString('{0} km', distance);

export const formattedTourFacts = (t: Tour): string => {
  const components = [];
  if (t.distance) components.push(formattedDistance(t.distance));
  if (t.altitude) components.push(formattedAltitude(t.altitude));
  if (t.pace) components.push(formattedPace(t.pace));
  return components.join(' | ');
};
