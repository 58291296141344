<template>
  <div v-if="state" class="flex flex-wrap place-content-start">
    <div
      class="w-1/2 aspect-w-2 aspect-h-1"
      v-for="(hit, i) in shuffle(state.hits).slice(0, displayCount)"
      :key="i"
    >
      <tour-archive-widget-cell
        :tour="tourFromObject(hit)"
      ></tour-archive-widget-cell>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectHits } from 'instantsearch.js/es/connectors';
import TourArchiveWidgetCell from './TourArchiveWidgetCell.vue';
import Tour from '@/dtos/tour';
import { shuffle } from '@/utils/shuffle';
export default Vue.extend({
  components: { TourArchiveWidgetCell },
  mixins: [createWidgetMixin({ connector: connectHits })],
  props: {
    displayCount: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  methods: {
    tourFromObject(hit) {
      return Tour.fromAlgoliaHit(hit);
    },
    shuffle(arr) {
      return shuffle(arr);
    },
  },
});
</script>
